import React, { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useInfiniteQuery } from '@tanstack/react-query';

import getCheckins from '@/services/checkins/getCheckins';

import { useCheckinStore } from '@/store/checkin';
import useStore from '@/store';

import { ListControllerProps, ListReservationsReturnTypes } from './types';
import { CardsReservationsData } from '@/components/CardsReservations/types';
import { GetCheckins } from '@/types/checkin';

export const useListReservationsController =
  (): ListReservationsReturnTypes => {
    const { ref, inView } = useInView();

    const { establishment, role, getList, updateGetList } = useStore(
      state => state
    );
    const { checkin, handleChangeRefreshCheckin, handleUpdateCheckin } =
      useCheckinStore();

    const [tabsName, setTabName] = useState<string>('ALL');
    const [dataCheckins, setDataCheckins] = useState<CardsReservationsData[]>(
      []
    );

    const {
      data,
      isLoading,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      refetch,
      isRefetching,
    } = useInfiniteQuery<any>({
      queryKey: ['checkins'],
      queryFn: getCheckins as any,
      initialPageParam: {
        page: 1,
        establishmentId: establishment?.id ?? '',
      },
      getNextPageParam: (lastPage: GetCheckins | undefined) => {
        if (lastPage?.totalPages === lastPage?.currentPage) {
          return undefined;
        }

        const nextPage = lastPage?.currentPage
          ? lastPage.currentPage + 1
          : undefined;

        return {
          page: nextPage,
          establishmentId: establishment?.id ?? '',
        };
      },
    });

    const filteredCheckins = (checkins: CardsReservationsData[]) => {
      if (tabsName.toLowerCase() !== 'all') {
        const filteredReservations = checkins?.filter(
          ({ status }: CardsReservationsData) =>
            status.toLowerCase() === tabsName.toLowerCase()
        );
        setDataCheckins(filteredReservations ?? []);
      } else {
        setDataCheckins(checkins);
      }
    };

    const flattenPages = async () => {
      const pages = data?.pages.flatMap(page => page?.data);
      const filteredData = pages?.filter(checkins => checkins !== undefined);

      filteredCheckins(filteredData ?? []);
    };

    const onChangeTabs = (name: string) => {
      if (!establishment?.id || isRefetching) return;
      setTabName(name);
    };

    const onRefresh = async () => {
      if (isRefetching) return;

      await refetch();
      flattenPages();

      updateGetList(false);
    };

    const updateDataCheckins = useCallback(async () => {
      const isInvalidCheckinData =
        !dataCheckins || dataCheckins.length === 0 || checkin === null;

      if (isInvalidCheckinData) {
        return;
      }

      const isCheckinAlreadyPresent = dataCheckins.some(
        ({ id }) => id === checkin.id
      );

      if (!isCheckinAlreadyPresent) {
        setDataCheckins([checkin, ...dataCheckins]);
      } else {
        const updatedCheckins = dataCheckins.map(item =>
          item.id === checkin.id ? checkin : item
        );
        setDataCheckins(updatedCheckins);
      }

      handleChangeRefreshCheckin(false);
      handleUpdateCheckin(null);
    }, [
      checkin,
      dataCheckins,
      setDataCheckins,
      handleChangeRefreshCheckin,
      handleUpdateCheckin,
    ]);

    useEffect(() => {
      if (isRefetching) return;

      flattenPages();
    }, [data]);

    useEffect(() => {
      updateDataCheckins();
    }, [checkin, updateDataCheckins]);

    useEffect(() => {
      onRefresh();
    }, [tabsName]);

    useEffect(() => {
      if (getList) onRefresh();
    }, [getList]);

    useEffect(() => {
      if (isLoading) return;

      if (inView && hasNextPage) {
        fetchNextPage();
      }
    }, [inView, fetchNextPage, hasNextPage, isLoading]);

    useEffect(() => {
      flattenPages();
    }, []);

    const checkins = dataCheckins ?? [];

    return {
      tabsName,
      onChangeTabs,
      role,
      isLoading,
      ref,
      isFetchingNextPage,
      isRefetching,
      checkins,
    };
  };

export default useListReservationsController;
