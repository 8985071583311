import React, { FC } from 'react';

import { CardsReservations } from '@/components/CardsReservations';
import { Tabs } from '@/components/common/Tabs';
import { Loading } from '@/components/common/Loading';
import { Alert } from '@/components/common/Alert';

import { tabsItems } from '@/utils/tabsItems';

import { useListReservationsController } from './useListReservationsController';
import { ListReservationsProps } from './types';

export const ListReservations: FC<ListReservationsProps> = ({
  isHome = false,
  getIncome,
}) => {
  const {
    tabsName,
    onChangeTabs,
    role,
    isLoading,
    checkins,
    ref,
    isFetchingNextPage,
    isRefetching,
  } = useListReservationsController();

  return (
    <>
      <div className="flex items-center justify-start gap-2.5 w-full mt-7 flex-wrap mb-[26px] lg:justify-end">
        <div className="flex items-center justify-start gap-2.5 w-full mt-7 flex-wrap mb-[26px] lg:justify-end">
          {tabsItems &&
            tabsItems?.map(({ name, value }) => {
              return (
                <Tabs
                  key={name}
                  text={name}
                  active={tabsName === value}
                  onClick={() => onChangeTabs(value)}
                />
              );
            })}
        </div>
        {role !== 'OWNER' && <Alert width={350} />}
      </div>
      <div className="flex flex-col w-full">
        {isLoading || isRefetching ? (
          <div
            data-testid="loading-indicator"
            className="table mx-auto my-0 pt-[50px]">
            <Loading borderColor="#fff" width={100} height={100} />
          </div>
        ) : (
          <>
            {isHome ? (
              <CardsReservations
                title="Suas reservas de hoje"
                data={checkins}
                time={'today'}
                getIncome={getIncome}
                innerRef={ref}
                fetchNewCard={isFetchingNextPage}
              />
            ) : (
              <>
                <CardsReservations
                  title="Suas reservas de hoje"
                  data={checkins}
                  time={'today'}
                  getIncome={getIncome}
                  innerRef={ref}
                />
                <CardsReservations
                  title="Suas reservas futuras"
                  data={checkins}
                  showButtonConfirm={false}
                  time={'future'}
                  getIncome={getIncome}
                  innerRef={ref}
                />
                <CardsReservations
                  title="Suas reservas de ontem"
                  data={checkins}
                  time={'yesterday'}
                  getIncome={getIncome}
                  innerRef={ref}
                />
                <CardsReservations
                  title="Suas reservas dessa semana"
                  data={checkins}
                  time={'week'}
                  getIncome={getIncome}
                  innerRef={ref}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ListReservations;
